<template>
  <div class="fuiler" id="target">
    <Header></Header>
    <div class="main">
      <div class="router">首页>>当季主题>>{{ Title }}</div>
      <div class="dataInfo">
        <div class="list">
          <div class="data" v-for="(item, index) in data" :key="index">
            <div class="block">
              <router-link
                :to="{
                  path: '/pc/browseText',
                  query: { _id: item._id, Title: Title },
                }"
                ><div class="title">{{ item.Title }}</div>
              </router-link>
              <div class="text"></div>
              <div class="Info">
                <div class="User">
                  <div class="ico"></div>
                  <div class="name"></div>
                  <div class="name">{{ item.NewTime }}</div>
                </div>
                <div class="interactive">
                  <div class="pv">浏览量：{{ item.Pv }}</div>
                  <div class="comment"><i></i>{{ item.Comment }}</div>
                  <div class="like"><i></i>{{ item.Give }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right"></div>
      </div>
      <div class="clear"></div>
    </div>

    <Footer></Footer>
    <div class="loading" v-if="loading">
      <van-loading />
    </div>
  </div>
</template>

<script>
import Header from "../conponents/Header";
import Footer from "../conponents/Footer";
import { find } from "../../../api/browseArticles";
import { Pagination, Loading } from "vant";

export default {
  data() {
    return {
      loading: false,
      query: {
        Page: 1,
        Total: 1,
      },
      Title: "",
      data: [],
    };
  },
  components: {
    Header,
    Footer,
    [Pagination.name]: Pagination,
    [Loading.name]: Loading,
  },
  mounted() {
    this.todata();
  },
  methods: {
    todata() {
      let query = this.$route.query;
      let Title = query.Title;
      this.Title = Title;
      this.loading = true;
      document.body.style.overflow = "hidden";
      let Page = this.query.Page;
      find({ Title, Page }).then((res) => {
        let data = res.sum;
        this.data = data;
        this.query.Total = res.number;
        this.loading = false;
        document.body.style.overflow = "auto";
        document.getElementById("target").scrollIntoView();
      });
    },
    black() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.fuiler {
  width: 100%;
  position: relative;
  background-color: #f6f6f6;
  background-size: 100% 100%;

  .main {
    width: 800px;
    margin: 0 auto;
    min-height: 1000px;
    margin-bottom: 20px;
    .router {
      font-size: 16px;
      color: #666;
      width: 100%;
      height: 50px;
      line-height: 50px;
      margin-bottom: 20px;
      background-color: #ffffff;
      box-shadow: 1px 1px 1px 1px #efefef;
      margin-top: 20px;
    }
    .dataInfo {
      width: 100%;
      height: auto;

      .list {
        float: left;
        background-color: #ffffff;
        .data {
          width: 100%;
          margin-bottom: 5px;
          border-bottom: #f0f2f6 2px solid;

          a {
            text-decoration: none;
            color: #000;
          }
          .block {
            width: 450px;
            height: 100px;
            padding: 20px;

            position: relative;
            .title {
              font-size: 16px;
              display: inline;
              font-weight: bold;
              color: #666;
            }
            .text {
              width: 100%;
              height: 65px;
            }
            .Info {
              font-size: 14px;
              user-select: none;
              .User {
                display: inline-block;
                margin-right: 20px;
                .ico {
                  background-color: rgba(234, 191, 235, 0.81);
                  border-radius: 10rem;
                  width: 8px;
                  height: 8px;
                  display: inline-block;
                  margin-right: 10px;
                }
                .name {
                  display: inline-block;
                  color: #666666;
                }
              }
              .interactive {
                display: inline-block;
                @mixin ico {
                  width: 0.9rem;
                  height: 0.9rem;
                  display: inline-block;
                  background-size: 100% 100%;
                  margin-right: 0.4rem;
                }
                .pv {
                  display: inline-block;
                  font-size: 14px;
                  color: #666666;
                  margin-right: 30px;
                }
                .comment {
                  display: inline-block;
                  i {
                    @include ico;
                    background-image: url("../../../assets/Image/ico/wenda.png");
                  }
                }
                .like {
                  margin-left: 0.4rem;
                  display: inline-block;
                  i {
                    @include ico;
                    background-image: url("../../../assets/Image/ico/dianzan.png");
                  }
                }
              }
            }
          }
        }
        .data:last-child {
          border: none;
        }
      }
      .right {
        float: right;
        width: 300px;
        height: 500px;
        background-color: #ffffff;
      }
    }
    .clear {
      clear: both;
    }
  }
}
</style>
